
import Title from "@/components/style/Title.vue";
import SecondTitle from "@/components/style/SecondTitle.vue";

let vueCarousel;
let components = {SecondTitle, Title};
if (process.client) {
  vueCarousel = require('vue-carousel');
  components.Carousel = vueCarousel.Carousel;
  components.Slide = vueCarousel.Slide;
}


export default {
  components,
  data() {
    return {
      pages: null,
      scale: false
    };
  },
  methods: {
    load() {
      this.$api.contents.query({
        type: 'discover-the-world',
        multiple: true,
        limit: 300,
        orderBy: 'rand-seed'
      }).then((res) => {
        return res.data.contents;
      });
    }
  },
  created() {
    if (process.client) {
      this.$nextTick(() => {
        this.scale = true;
      });
    }
  },
  async fetch() {
    const pages = await this.$api.contents.query({
      type: 'discover-the-world',
      multiple: true,
      limit: 300,
      orderBy: 'rand-seed'
    }).then((res) => {
      return res.data.contents;
    })
        .catch((e) => {
          console.error('error', e);
        });
    // this.pages = []; //pages;
    this.pages = pages;
  }
}
