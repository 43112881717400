
import ClickOutside from 'vue-click-outside';
import Dropdown from "@/components/input/Dropdown.vue";
import CalendarInternal from "@/components/input-2/CalendarInternal.vue";
import Button from "@/components/input-2/Button.vue";

export default {
  components: {Button, CalendarInternal, Dropdown},
  props: {
    usage: {default: 'departure', type: String},
    trip: {type: String},
    focus: {default: false, type: Boolean}
  },
  directives: {
    // ClickOutside
  },
  computed: {
    valueInput() {
      if (this.trip === 'departureDate') {
        if (this.$store.state.searchFlight.departure_date) {
          if (this.$store.state.searchFlight.departure_date === 'X') {
            return 'X';
          }
          return this.$moment(this.$store.state.searchFlight.departure_date);
        } else {
          return this.$moment().add(7, 'days');
        }
      } else if (this.trip === 'returnDate') {
        if (this.$store.state.searchFlight.return_date) {
          if (this.$store.state.searchFlight.return_date === 'X') {
            return 'X';
          }
          return this.$moment(this.$store.state.searchFlight.return_date);
        } else {
          return this.$moment().add(14, 'days');
        }
      }
    }
  },
  methods: {
    setAnyDate() {
      if (this.trip === 'departureDate')
        this.$store.commit('searchFlight/setDepartureDate', 'X');
      else if (this.trip === 'returnDate')
        this.$store.commit('searchFlight/setReturnDate', 'X');
      this.closeFocus();
    },
    closeFocus() {
      this.$emit('closeFocus');
      this.$emit('update:focus', false);
    },
    keyPress(event) {
      if (this.focus) {
        switch (event.keyCode) {
          case 38:
            this.newSelection(-1);
            break;
          case 40:
            this.newSelection(+1);
            break;
        }

        if (event.key === 'Enter' || event.keyCode === 13) {
          this.selectCurrent();
        }
      }
    },
  }
}
