import { render, staticRenderFns } from "./DiscoverTheWorld.vue?vue&type=template&id=2965a19e"
import script from "./DiscoverTheWorld.vue?vue&type=script&lang=js"
export * from "./DiscoverTheWorld.vue?vue&type=script&lang=js"
import style0 from "./DiscoverTheWorld.vue?vue&type=style&index=0&id=2965a19e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports