
import Title from "@/components/style/Title.vue";

export default {
  components: {Title},
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  }
}
