
export default {
  props: {
    size: {
      default: ''
    },
    href: {},
    type: {
      default: 'button'
    },
    full: {
      default: false
    },
    color: {
      default: ''
    },
    disabled: {
      default: false
    },
    to: {
      default: false
    },
    target: {
      default: ''
    },
    icon: {
      default: false
    },
    classes: {
      default: false
    },
    loading: {
      default: false
    }
  }
}
