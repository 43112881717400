
export default {
  props: {
    value: {

    },
    trueValue: {
      default: true
    },
    label: {
      default: ''
    },
    inline: {
      default: false
    },
    noBox: {
      default: false
    },
    dense: {
      default: false
    }
  },
  computed: {
    valueLocal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
