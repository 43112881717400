
export default {
  props: {
    tag: {
      default: 'h1'
    },
    size: {
      default: ''
    }
  }
}
