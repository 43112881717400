
import Money from '../helpers/Money';

export default {
  components: {Money},
  props: {
    trip: {type: String},
    focus: {type: Boolean}
  },
  data() {
    return {
      currentView: null,
      days: {},
      classes: {},
      hoverDate: null
    };
  },
  methods: {
    getPrice(day) {
      let date = day.day.format('YYYY-MM-DD');
      let key = this.$store.state.searchFlight.departure.airport_code + '~' + this.$store.state.searchFlight.destination.airport_code + '~' + this.$store.state.searchFlight.search_type;

      if (!this.$store.state.cacheFlightPrices.cache[key]) return '●●●';

      if (this.trip === 'departureDate') {
        if (this.$store.state.cacheFlightPrices.cache[key].outward && this.$store.state.cacheFlightPrices.cache[key].outward[date] && this.$store.state.cacheFlightPrices.cache[key].outward[date].price_adult) {
          return this.$store.state.cacheFlightPrices.cache[key].outward[date].price_adult;
        }
        return '●●●';
      } else {
        if (this.$store.state.cacheFlightPrices.cache[key].return && this.$store.state.cacheFlightPrices.cache[key].return[date] && this.$store.state.cacheFlightPrices.cache[key].return[date].price_adult) {
          return this.$store.state.cacheFlightPrices.cache[key].return[date].price_adult;
        }
        return '●●●';
      }
    },
    setHoverDate(day) {
      if (day && day.day) {
        this.hoverDate = day.day;
      } else {
        this.hoverDate = null;
      }
      this.setBackgrounds();
    },
    setDate(day) {
      if (this.trip === 'departureDate') {
        this.$store.commit('searchFlight/setDepartureDate', day.day.format('YYYY-MM-DD'));
      } else {
        this.$store.commit('searchFlight/setReturnDate', day.day.format('YYYY-MM-DD'));
      }
      this.$emit('close');
    },
    resetHover() {
      this.hoverDate = this.$moment('1970-01-01');
      this.setDays();
    },
    setDays() {
      let currentDate = this.startView.clone().set('hour', 12).set('minute', 0).utc();
      let endDate = this.endView.clone().set('hour', 12).set('minute', 0).utc();

      this.days = {};
      let week = 0;

      let departureDate = this.$moment(this.$store.state.searchFlight.departure_date);
      let returnDate = this.$moment(this.$store.state.searchFlight.return_date);

      while (currentDate.unix() <= endDate.unix()) {

        let keyMonth = currentDate.format('YYYYMM');
        if (typeof this.days[keyMonth] === 'undefined') {
          this.days[keyMonth] = {
            days: {},
            month: currentDate.clone()
          }
        }

        let keyDay = (parseInt(currentDate.format('E')) + parseInt(this.$i18n.localeProperties.startWeek)) % 7;

        if (keyDay % 7 === 0) {
          week++;
        }

        if (typeof this.days[keyMonth].days[week] === 'undefined') {
          this.days[keyMonth].days[week] = {};
        }

        this.days[keyMonth].days[week][keyDay] = {
          day: currentDate.clone()
        };

        if (currentDate.unix() > this.limitMax.unix() || currentDate.unix() < this.limitMin.unix()) {
          this.days[keyMonth].days[week][keyDay].deactivated = true;
        }

        if (this.trip === 'departureDate' && departureDate && currentDate.startOf('day').unix() === departureDate.startOf('day').unix()) {
          this.days[keyMonth].days[week][keyDay].selected = true;
        }
        // if (typeof this.$store.state.searchFlight.return_date === 'string') {
        //   this.$store.commit('searchFlight/setReturnDate', this.$store.state.searchFlight.return_date);
        // }

        if (this.trip === 'returnDate' && returnDate && currentDate.startOf('day').unix() === returnDate.startOf('day').unix()) {
          this.days[keyMonth].days[week][keyDay].selected = true;
        }

        currentDate.add(1, 'day');
      }

      // Fill gaps
      Object.keys(this.days)
          .forEach((month_key) => {
            Object.keys(this.days[month_key].days)
                .forEach((days_key) => {
                  if (Object.keys(this.days[month_key].days[days_key]).length < 7) {
                    let first_day_key = Object.keys(this.days[month_key].days[days_key])[0];

                    for (let i = 0; i < 7; i++) {
                      if (typeof this.days[month_key].days[days_key][i] === 'undefined') {
                        this.days[month_key].days[days_key][i] = {};

                        if (this.$moment(this.days[month_key].days[days_key][first_day_key].day).format('D') === '1') {
                          this.days[month_key].days[days_key][i].day = this.$moment(this.days[month_key].days[days_key][first_day_key].day)
                              .subtract(
                                  first_day_key - i,
                                  'days'
                              );
                        } else {
                          this.days[month_key].days[days_key][i].day = this.$moment(this.days[month_key].days[days_key][first_day_key].day)
                              .add(
                                  i,
                                  'days'
                              );
                        }
                        this.days[month_key].days[days_key][i].other_month = true;

                        if (this.$moment(this.days[month_key].days[days_key][first_day_key].day).unix() > this.limitMax.unix() || this.$moment(this.days[month_key].days[days_key][first_day_key].day).unix() < this.limitMin.unix()) {
                          this.days[month_key].days[days_key][i].deactivated = true;
                        }
                      }
                    }

                  }
                });
          });
      this.setBackgrounds();
    },
    prevMonth() {
      if (!this.prevMonthEnable) return;
      this.currentView = this.$moment(this.currentView).subtract(1, 'month');
      console.log(this.currentView.format('c'));
    },
    nextMonth() {
      if (!this.nextMonthEnable) return;
      this.currentView = this.$moment(this.currentView).add(1, 'month');
      console.log(this.currentView.format('c'));
    },

    setBackgrounds(selected_day) {
      this.classes = {};

      let currentDate = this.startView.clone().set('hour', 12).set('minute', 0).utc();
      let endDate = this.endView.clone().set('hour', 12).set('minute', 0).utc();

      let departureDate = this.$moment(this.$store.state.searchFlight.departure_date).set('hour', 12).set('minute', 0).utc();
      let returnDate = this.$moment(this.$store.state.searchFlight.return_date).set('hour', 12).set('minute', 0).utc();

      while (currentDate.unix() <= endDate.unix()) {
        this.classes[currentDate.format('YYYYMMDD')] = {};
        // Set the data from departure until the hover one enabled
        if (this.trip === 'returnDate' && departureDate && departureDate.isValid && this.hoverDate && this.hoverDate.isValid) {
          if (currentDate.unix() > departureDate.unix() && currentDate.unix() <= this.hoverDate.unix()) {
            this.classes[currentDate.format('YYYYMMDD')].between_day = true;
          }
        }

        currentDate.add(1, 'day');
      }

      if (typeof this.classes[this.hoverDate.format('YYYYMMDD')] === 'undefined') {
        this.classes[this.hoverDate.format('YYYYMMDD')] = {};
      }

      this.classes[this.hoverDate.format('YYYYMMDD')].hover = true;

      if (this.trip === 'returnDate' && returnDate && returnDate.isValid) {
        if (typeof this.classes[returnDate.format('YYYYMMDD')] === "undefined") {
          this.classes[returnDate.format('YYYYMMDD')] = {};
        }
        this.classes[returnDate.format('YYYYMMDD')].selected = true;
      }
      if (departureDate && departureDate.isValid) {
        if (typeof this.classes[departureDate.format('YYYYMMDD')] === "undefined") {
          this.classes[departureDate.format('YYYYMMDD')] = {};
        }
        this.classes[departureDate.format('YYYYMMDD')].selected = true;
      }

      if (typeof this.classes[departureDate.format('YYYYMMDD')] === "undefined") {
        this.classes[departureDate.format('YYYYMMDD')] = {};
      }


      if (this.trip === 'returnDate') {


      }


      console.log('hover', this.hoverDate);

      return;
    }
  },
  computed: {
    startView() {
      return this.$moment(this.currentView).startOf('month').set('hour', 12).set('minute', 0);
    },
    endView() {
      return this.$moment(this.currentView).endOf('month').set('hour', 12).set('minute', 0);
    },
    limitMin() {
      if (this.trip === 'departureDate') {
        return this.$moment();
      } else {
        return this.$moment(this.$store.state.searchFlight.departure_date || this.$moment());
      }
    },
    limitMax() {
      return this.$moment().add(1, 'year');
    },
    prevMonthEnable() {
      return this.limitMin.unix() < this.$moment(this.currentView).startOf('month').unix();
    },
    nextMonthEnable() {
      return this.limitMax.unix() > this.$moment(this.currentView).endOf('month').unix();
    },
    // currentDate: {
    //   get() {
    //     if (this.trip === 'departureDate') {
    //       return this.$store.state.searchFlight.departure_date;
    //     } else {
    //       return this.$store.state.searchFlight.return_date;
    //     }
    //   },
    //   set(newValue) {
    //     this.$store.commit('searchFlight/setDepartureDate', newValue);
    //   }
    // }
  },
  created() {
    this.hoverDate = this.$moment('1984-12-24');
  },
  watch: {
    focus() {
      if (this.trip === 'departureDate') {
        if (this.$store.state.searchFlight.departure_date !== 'X')
          this.currentView = this.$moment(this.$store.state.searchFlight.departure_date);
        else
          this.currentView = this.$moment();
      } else {
        if (this.$store.state.searchFlight.return_date !== 'X')
          this.currentView = this.$moment(this.$store.state.searchFlight.return_date);
        else
          this.currentView = this.$moment();
      }
    },
    currentView() {
      this.setDays();
    }
  }
};
