
import ClickOutside from 'vue-click-outside';
import Dropdown from '../input/Dropdown';

export default {
  components: {Dropdown},
  props: {
    usage: {default: 'departure', type: String}
  },
  directives: {
    // ClickOutside
  },
  data() {
    return {
      search: '',
      focus: false,
      currentSelection: 0,
      timeout: null,
      using_defaults: false,
      loading: false,
      results: [],
      selected: null,
      default_results: [],
    }
  },
  methods: {
    hide(usage) {
      this.focus = false;
    },
    selectCurrent(e) {
      if (e)
        e.preventDefault();
      this.selected = this.results[this.currentSelection];
      if (this.usage === 'departure') {
        this.$store.commit('searchFlight/setDeparture', this.results[this.currentSelection]);
      } else {
        this.$store.commit('searchFlight/setDestination', this.results[this.currentSelection]);
      }
      console.log('store', this.$store.state);
      this.$nextTick(() => {
        this.focus = false;
      });
    },
    load() {
      if (this.search !== '') {
        this.using_defaults = false;
        this.loading = true;
        this.$api.flightAirports.autocomplete(this.search, {usage: this.usage})
            .then((res) => {
              this.results = res.data.airports;
              this.$nextTick(() => {
                this.loading = false;
              });
            });
      } else {
        if (this.usage === 'departure') {
          this.results = this.default_results;
          this.using_defaults = true;
        }
      }
    },
    keyPress(event) {
      if (this.focus) {
        switch (event.keyCode) {
          case 38:
            this.newSelection(-1);
            break;
          case 40:
            this.newSelection(+1);
            break;
        }

        if (event.key === 'Enter' || event.keyCode === 13) {
          this.selectCurrent();
        }
      }
    },
    newSelection(value) {
      if (this.currentSelection === null) this.currentSelection = 0;
      else {
        this.currentSelection += value;

        if (typeof this.results[this.currentSelection] === 'undefined') {
          if (this.currentSelection > this.results.length - 1) {
            this.currentSelection = this.results.length - 1;
          } else if (this.currentSelection < 0) {
            this.currentSelection = 0;
          }
        }
      }
    },
    focusInput(e) {
      this.focus = true;
      e.target.select();

      if (this.$responsive.isMobile()) {
        console.log("scrolling");
        const inputRect = e.target.getBoundingClientRect();
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

        // Check if the input field is not already at the top
        if (inputRect.top + scrollTop > 0) {
          // Scroll to the top of the input field
          window.scrollTo({
            top: inputRect.top + scrollTop - 40,
            behavior: 'smooth',
          });
        }
      }
    }
  },
  created() {
    if (this.usage === 'departure') {
      if (this.default_results.length === 0) {
        this.$api.flightAirports.position()
            .then((res) => {
              this.default_results = res.data.airports;
              this.results = res.data.airports;
              this.using_defaults = true;
            });
      } else {
        this.results = this.default_results;
        this.using_defaults = true;
      }
    }
    if (this.usage === 'destination') {
      if (this.default_results.length === 0) {
        this.$api.flightAirports.destinations()
            .then((res) => {
              this.default_results = res.data.airports;
              this.results = res.data.airports;
              this.using_defaults = true;
            });
      } else {
        this.results = this.default_results;
        this.using_defaults = true;
      }
    }
  },
  watch: {
    // focus: {
    //   immediate: true,
    //   handler(v) {
    //     if (v) {
    //       this.scrollToTop();
    //     }
    //   }
    // },
    '$store.state.searchFlight.departure': {
      immediate: true,
      handler(newVal) {
        if (this.usage === 'departure') {
          this.selected = newVal;
        }
      }
    },
    '$store.state.searchFlight.destination': {
      immediate: true,
      handler(newVal) {
        if (this.usage === 'destination') {
          this.selected = newVal;
        }
      }
    },
    search: {
      immediate: true,
      handler(value) {
        // if (this.notLoad === false) {
        if (typeof this.timeout !== 'undefined')
          clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.load(this.options);
        }, 300);
        // }
      }
    }
  }
}
