
export default {
  components: {},
  props: {},
  data() {
    return {
      links_aircompanies: []
    };
  },
  async fetch() {
    let otherAircompanies = this.page = await this.$api.internal.get('summary/aircompany')
        .then(response => {
          return response.data;
        });

    let seeder = this.$moment().format('HHDDMMYYYY') +
        this.$helpers.stringToNumberSeed(this.$route.path);

    otherAircompanies = this.$helpers.shuffleSeeder(otherAircompanies, seeder);
    this.links_aircompanies = this._.slice(otherAircompanies, 0, 15);
  }
};
