
import ClickOutside from 'vue-click-outside';
import LabelHeader from "@/components/input-2/LabelHeader.vue";
import Calendar from "@/components/input-2/Calendar.vue";
import CalendarInternal from "~/components/input-2/CalendarInternal.vue";

export default {
  components: {CalendarInternal, Calendar, LabelHeader},
  props: {
    usage: {default: 'departure', type: String}
  },
  directives: {
    ClickOutside
  },
  computed: {
    adults: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.adults;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setAdults', newValue);
      }
    },
    children: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.children;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setChildren', newValue);
      }
    },
    infants: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.infants;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setInfants', newValue);
      }
    },
    travelclass: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.travelclass;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setTravelclass', newValue);
      }
    }
  },

  methods: {
    update(variable, addition) {
      addition = 1 * addition;

      if (variable === 'adults') {
        this.adults = this.adults + addition;
        if (this.adults === 0) {
          this.adults = 1;
        } else if (this.adults === 10) {
          this.adults = 9;
        }
        if (this.adults === 1) {
          this.disabledAdultsMinus = true;
        }
        if (this.adults === 9) {
          this.disabledAdultsPlus = true;
        }
        if (this.adults + this.children + this.infants === 10) {
          this.adults = this.adults - addition;
        }
        if (this.adults < this.infants) {
          this.infants = this.adults;
        }
        this.fix();
        this.updateLabel();
      } else if (variable === 'children') {
        this.children = this.children + addition;
        if (this.children === -1) {
          this.children = 0;
        } else if (this.children === 10) {
          this.children = 9;
        }
        if (this.children === 0) {
          this.disabledChildrenMinus = true;
        }
        if (this.children === 9) {
          this.disabledChildrenPlus = true;
        }
        if (this.adults + this.children + this.infants === 10) {
          this.children = this.children - addition;
        }
        this.fix();
        this.updateLabel();
      } else if (variable === 'infants') {
        this.infants = this.infants + addition;
        if (this.infants === -1) {
          this.infants = 0;
        } else if (this.infants === 10) {
          this.infants = 9;
        }
        if (this.infants === 0) {
          this.disabledInfantsMinus = true;
        }
        if (this.infants === 9) {
          this.disabledInfantsPlus = true;
        }
        if (this.adults + this.children + this.infants === 10) {
          this.infants = this.infants - addition;
        }
        if (this.adults < this.infants) {
          this.infants = this.infants - addition;
        }
        this.fix();
        this.updateLabel();
      }
    },
    fix() {
      this.disabledAdultsMinus = false;
      this.disabledAdultsPlus = false;
      this.disabledChildrenMinus = false;
      this.disabledChildrenPlus = false;
      this.disabledInfantsMinus = false;
      this.disabledInfantsPlus = false;

      if (this.adults === 0) {
        this.adults = 1;
      } else if (this.adults === 10) {
        this.adults = 9;
      }
      if (this.adults === 1) {
        this.disabledAdultsMinus = true;
      }
      if (this.adults === 9) {
        this.disabledAdultsPlus = true;
      }

      if (this.children === -1) {
        this.children = 0;
      } else if (this.children === 10) {
        this.children = 9;
      }
      if (this.children === 0) {
        this.disabledChildrenMinus = true;
      }
      if (this.children === 9) {
        this.disabledChildrenPlus = true;
      }

      if (this.infants === -1) {
        this.infants = 0;
      } else if (this.infants === 10) {
        this.infants = 9;
      }
      if (this.infants === 0) {
        this.disabledInfantsMinus = true;
      }
      if (this.infants === 9) {
        this.disabledInfantsPlus = true;
      }
      if (this.infants === this.adults) {
        this.disabledInfantsPlus = true;
      }

      if (this.adults + this.children + this.infants === 9) {
        this.disabledAdultsPlus = true;
        this.disabledChildrenPlus = true;
        this.disabledInfantsPlus = true;
      }
    },
    updateLabel() {
      if (this.children + this.infants === 0) {
        this.localLabel = this.$tc('search-flight.Passengers Adults', this.adults,
            {adults: this.adults});
      } else {
        this.localLabel = this.$tc('search-flight.Passengers Total',
            this.adults + this.children + this.infants,
            {passengers: this.adults + this.children + this.infants});
      }
      this.$emit('update:label', this.localLabel);
    },
    toggle(e) {
      this.focus = !this.focus;

      if (this.$responsive.isMobile()) {
        const inputRect = e.target.getBoundingClientRect();
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

        // Check if the input field is not already at the top
        if (inputRect.top + scrollTop > 0) {
          // Scroll to the top of the input field
          window.scrollTo({
            top: inputRect.top + scrollTop - 40,
            behavior: 'smooth',
          });
        }
      }
    },
    activate() {
      this.focus = true;
    },
    hide() {
      this.focus = false;
    },
    keyPress() {

    }
  },
  data() {
    return {
      disabledAdultsMinus: true,
      disabledAdultsPlus: true,
      disabledChildrenMinus: true,
      disabledChildrenPlus: true,
      disabledInfantsMinus: true,
      disabledInfantsPlus: true,
      localLabel: '',
      focus: false
    }
  },
  created() {
    this.fix();
    this.updateLabel();
  },
}
