
import Card from '../../helpers/Card';

export default {
  components: {Card},
  data() {
    return {
      searches: []
    };
  },
  created() {
    this.load();
    // let searches = localStorage.getItem('last_searches');
    // if (typeof searches !== 'undefined' && searches !== null) {
    //     searches        = JSON.stringify(searches);
    //     let searches_ok = [];
    //     searches.forEach((search) => {
    //         if (this.$moment(search.departure_date) > this.$moment()) {
    //             searches_ok.push(search);
    //         }
    //     });
    //     this.searches = searches_ok;
    // }
  },
  methods: {
    load() {
      if (process.client) {
        this.$api.userDataSession.flightSearch()
            .then((res) => {
              this.searches = res.data.flight_search.slice(0, 4);
            });
      }
    },
    goToSearch(search) {
      this.$router.push({
        path: this.localeRoute({
          name: 'search-flight-code',
          params: {code: encodeURIComponent(search.search_code)}
        }).fullPath
      });
    }
  },
  watch: {
    '$store.state.dataSession.key': function (value) {
      console.log('store', value);
      if (value !== '') {
        this.load();
      }
    }
  }
};
