
export default {
  props: ['showForm'],
  computed: {
    showFormLocal: {
      get() {
        return this.showForm;
      },
      set(value) {
        this.$emit('update:showForm', value);
      }
    },
    passengerLabel() {
      if (this.$store.state.searchFlight.children + this.$store.state.searchFlight.infants === 0) {
        return this.$tc('search-flight.Passengers Adults', this.$store.state.searchFlight.adults,
            {adults: this.$store.state.searchFlight.adults});
      } else {
        return this.$tc('search-flight.Passengers Total',
            this.$store.state.searchFlight.adults + this.$store.state.searchFlight.children + this.$store.state.searchFlight.infants,
            {passengers: this.$store.state.searchFlight.adults + this.$store.state.searchFlight.children + this.$store.state.searchFlight.infants});
      }
    }
  },
  mounted() {
    this.$globalEvent.$on('show-form-edit', () => {
      this.showFormLocal = true;
    });
  },
  unmounted() {
    this.$globalEvent.$off('show-form-edit');
  }
};
