import { render, staticRenderFns } from "./Features.vue?vue&type=template&id=0d8f5419&scoped=true"
import script from "./Features.vue?vue&type=script&lang=js"
export * from "./Features.vue?vue&type=script&lang=js"
import style0 from "./Features.vue?vue&type=style&index=0&id=0d8f5419&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8f5419",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/code/current/node_modules/vue-ssr-carousel/index.js').default})
