
import SsrCarousel from 'vue-ssr-carousel';
import ssrCarouselCss from 'vue-ssr-carousel/index.css';
import DateTravel from '../../input/DateTravel';

export default {
  components: {
    SsrCarousel
  },

  data() {
    return {
      slide: 0
    };
  }
};
