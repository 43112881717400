
export default {
  props: {
    label: {
      default: '',
      type: String
    },
    dense: {
      default: false
    }
  },
}
