
import Dropdown from '~/components/input/Dropdown';
import PreviewFlight from './PreviewFlight';
import Flight from "~/components/partials/form/Flight.vue";
import AnyFlightLogo from "~/components/partials/AnyFlightLogo.vue";
import Menu from "@/components/partials/header/menu.vue";

export default {
  components: {Menu, Flight, PreviewFlight, Dropdown, AnyFlightLogo},
  props: {
    image: {type: String, default: null},
    classesHeader: {type: String, default: 'md:h-80'},
    minimal: {type: Boolean, default: false},
    almostNone: {type: Boolean, default: false},
    minimalWLinks: {type: Boolean, default: false},
    classList: {type: String},
    formPreview: {type: Boolean, default: false},
    fixed: {type: Boolean, default: true},
    onBack: {type: Boolean | Function, default: false},
    logoMobile: {type: Boolean, default: false},
    visibleMobile: {type: Boolean, default: false},
    visibleDesktop: {type: Boolean, default: true},
    imageMobile: {type: Boolean, default: false},
    hideMobileText: {type: Boolean, default: false},
    formVisible: {type: Boolean, default: true},
    smallTip: {type: Boolean, default: false},
    imageMobileClasses: {type: String, default: ''},
    mobileSpecificClasses: {type: String, default: ''},
  },
  data() {
    return {
      languageOpen: false,
      showForm: false,
      openMobileMenu: false
    };
  },
  methods: {
    reload() {
      let url = this.localeRoute({
        name: this.$route.name.split('___')[0],
        params: this.$route.params,
        query: Object.assign(this.$route.query, {reload: '1'})
      }).fullPath;

      window.location.href = url;
    },
    setForm(value) {
      // value.departure_date = this.$moment(value.departure_date);
      // if (value.return_date !== null)
      //     value.return_date = this.$moment(value.return_date);
      // else
      //     value.return_date = this.$moment(value.departure_date)
      //                             .add(7, 'days');
      // this.$refs.formFlight.setForm(value);
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    },
    showPreview() {
      return this.$responsive.isMobile() && this.formPreview && !this.showForm;
    }
  },
  beforeDestroy() {
    window.document.body.style.overflow = 'auto';
  },
  created() {
    this.$helpers.customCurrency();
  },
  watch: {
    openMobileMenu: function (value) {
      if (value) {
        window.document.body.style.overflow = 'hidden';
      } else {
        window.document.body.style.overflow = 'auto';
      }
    }
  }
};
