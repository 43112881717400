
import ClickOutside from 'vue-click-outside';
import LabelHeader from "@/components/input-2/LabelHeader.vue";
import Calendar from "@/components/input-2/Calendar.vue";
import Validation from "~/components/input-2/Validation.vue";

export default {
  components: {Validation, Calendar, LabelHeader},
  props: {
    usage: {default: 'departure', type: String}
  },
  directives: {
    ClickOutside
  },
  created() {
    this.loadPrices();
  },
  computed: {
    tripsFields() {
      if (this.$store.state.searchFlight.search_type === 'roundtrip') {
        return ['departureDate', 'returnDate'];
      }
      return ['departureDate'];
    }
  },
  methods: {
    test() {
      console.log(
          'testdata',
          this.$store.state.searchFlight.departure_date,
          this.$store.state.searchFlight.return_date,
      );
    },
    verifyIntegrity() {
      console.log('verify', this.$store.state.searchFlight.return_date);
      if (this.$moment(this.$store.state.searchFlight.return_date).unix() < this.$moment(this.$store.state.searchFlight.departure_date).unix()) {
        this.$store.commit('searchFlight/setReturnDate', this.$store.state.searchFlight.departure_date);
      }
    },
    activateCalendar(calendar) {
      console.log('show ', calendar);
      this.focus[calendar] = true;
    },
    toggleCalendar(calendar, e) {
      this.focus[calendar] = !this.focus[calendar];
      if (calendar === 'departureDate') {
        this.focus['returnDate'] = false;
      } else {
        this.focus['departureDate'] = false;
      }

      if (this.$responsive.isMobile()) {
        const inputRect = e.target.getBoundingClientRect();
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

        // Check if the input field is not already at the top
        if (inputRect.top + scrollTop > 0) {
          // Scroll to the top of the input field
          window.scrollTo({
            top: inputRect.top + scrollTop - 40,
            behavior: 'smooth',
          });
        }
      }
    },
    label(label) {
      if (label === 'departureDate') {
        return this.$t('search-flight.Departure Date');
      }
      return this.$t('search-flight.Return Date');
    },
    keyPress() {

    },
    hide(calendar) {
      this.focus[calendar] = false;
    },
    loadPrices() {
      if (this.$store.state.searchFlight.departure && this.$store.state.searchFlight.departure.airport_code && this.$store.state.searchFlight.destination && this.$store.state.searchFlight.destination.airport_code) {
        if (!this.$store.state.cacheFlightPrices.cache[this.$store.state.searchFlight.departure.airport_code + '~' + this.$store.state.searchFlight.destination.airport_code + '~' + this.$store.state.searchFlight.search_type]) {
          this.$api.flightCalendar.simple(this.$store.state.searchFlight.departure.airport_code, this.$store.state.searchFlight.destination.airport_code, this.$store.state.searchFlight.search_type)
              .then((res) => {
                console.log('calendar', res.data.calendar);
                this.$store.commit('cacheFlightPrices/setPrices', {
                  calendar: res.data.calendar.calendar,
                  departure: this.$store.state.searchFlight.departure.airport_code,
                  destination: this.$store.state.searchFlight.destination.airport_code,
                  search_type: this.$store.state.searchFlight.search_type
                });
              });
        }
      }
    }
  },
  data() {
    return {
      focus: {
        departureDate: false,
        returnDate: false
      }
    }
  },
  watch: {
    '$store.state.searchFlight.departure': {
      handler() {
        this.loadPrices();
      }
    },
    '$store.state.searchFlight.destination': {
      handler() {
        this.loadPrices();
      }
    },
    '$store.state.searchFlight.departure_date': {
      handler() {
        this.verifyIntegrity();
      }
    },
    '$store.state.searchFlight.return_date': {
      handler() {
        this.verifyIntegrity();
      }
    },
    '$store.state.searchFlight.search_type': {
      handler() {
        this.loadPrices();
      }
    }
  }
}
