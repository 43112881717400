var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"flex justify-end mr-8"},[_c('li',[_c('nuxt-link',{staticClass:"p-2 hover-effect",attrs:{"to":_vm.localePath({
                                name: 'index'
                            })}},[_vm._v(_vm._s(_vm.$t('header.Flights')))])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"p-2 hover-effect",attrs:{"to":_vm.localePath({
              name: 'booking-status'
              })}},[_c('span',{staticClass:"lg:inline-block"},[_vm._v("\n          "+_vm._s(_vm.$t('header.Booking Status'))+"\n        ")])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"p-2 hover-effect",attrs:{"to":_vm.localePath({
              name: 'contact'
              })}},[_c('span',{staticClass:"lg:inline-block"},[_vm._v("\n          "+_vm._s(_vm.$t('header.Contact'))+"\n        ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }