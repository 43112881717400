
import Header from '../components/partials/Header';
import Features from '../components/partials/marketing/Features';
import BackgroundGray from '../components/helpers/BackgroundGray';
import Card from '../components/helpers/Card';
import LastSearches from '../components/partials/landing/LastSearches';
import PreFooter from '../components/partials/PreFooter';
import Footer from '../components/partials/Footer';
import FlightsDestinations from '@/components/partials/landing/FlightsDestinations';
import DiscoverTheWorld from "~/components/partials/marketing/DiscoverTheWorld.vue";
import PopularDestinations from "@/components/partials/marketing/PopularDestinations.vue";
import ListAirlines from "@/components/partials/ListAirlines.vue";

export default {
  components: {
    ListAirlines,
    PopularDestinations,
    DiscoverTheWorld,
    FlightsDestinations, Footer, PreFooter, LastSearches, Card, BackgroundGray, Features, Header
  },
  data() {
    return {
      page: {},
      destinations: [],
      links_related: [],
      aircompanies: []
    };
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  },
  created() {
    console.log('router', this.$route);
  },
  async fetch() {
    // Use Promise.all to make both API calls concurrently
    const [pageResponse, aircompaniesResponse] = await Promise.all([
      this.$api.contents.query({
        multiple: false,
        code: 'homepage'
      }),
      this.$api.contents.query({
        type: 'aircompany',
        multiple: true,
        limit: 12,
        orderBy: 'rand-seed'
      })
    ]);

    // Extract data from the responses
    this.page = pageResponse.data.content;
    this.aircompanies = aircompaniesResponse.data.contents;

    // Set SEO data
    this.$store.commit('seo/setTitle', this.page.seo.title);
    this.$store.commit('seo/setDescription', this.page.seo.description);
    this.$store.commit('seo/setKeywords', this.page.seo.keywords);
    this.$store.commit('seo/setImage', this.page.image + '?format=webp');
    this.$store.commit('seo/setCanonical', this.localeRoute({
      name: 'index'
    }).fullPath);
  }
};
