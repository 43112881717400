
import Card from '../../helpers/Card';
import Money from '@/components/helpers/Money';

export default {
  components: {Money, Card},
  props: [
    'columns', 'smColumns', 'mdColumns', 'rows', 'smRows', 'mdRows'
  ],
  data() {
    return {
      items: []
    };
  },
  computed: {
    sizeScreen() {
      if (this.$responsive.isTablet()) {
        return 'md';
      } else if (this.$responsive.isMobile()) {
        return 'sm';
      }
      return 'lg';
    },
    boxSizes() {
      let boxSizes = new Array(100);
      boxSizes.fill(1, 0, 85);
      boxSizes.fill(2, 84, 95);
      boxSizes.fill(3, 94, 100);

      return boxSizes;
    },
    seeder() {
      let seed = parseInt(this.$moment()
              .tz('Europe/Bucharest')
              .format('H')) *
          parseInt(this.$moment()
              .tz('Europe/Bucharest')
              .format('M')) *
          parseInt(this.$moment()
              .tz('Europe/Bucharest')
              .format('D'));
      return seed;
    }
  },
  methods: {
    urlImage(element) {
      if (element.item.photos && element.item.photos.length) {
        let photo = this.$helpers.shuffleSeeder(element.item.photos, this.$moment().format('HHDDMMYYYY')) [0];
        switch (element.size) {
          case 1:
            return photo + '?w=400&h=400&resize=false&type=webp';
          case 2:
            return photo + '?w=800&h=400&resize=false&type=webp';
          case 3:
            return photo + '?w=1200&h=400&resize=false&type=webp';
        }
      }
    },
    sizeClass(element) {
      if (this.sizeScreen === 'sm') {
        if (element.size === 2) {
          return 'w-full';
        } else {
          return 'w-' + element.size + '/' + this.smColumns;
        }
      } else if (this.sizeScreen === 'md') {
        if (element.size === 3) {
          return 'w-full';
        }
        return 'w-' + (element.size * 4) + '/' + (this.mdColumns * 4);
      } else {
        return 'w-' + element.size + '/' + (this.sizeScreen === 'sm' ? '2' : '5');
      }
    },
    firstShuffleArray(array) {
      return this.shuffleArray(array)[0];
    },
    shuffleArray(array) {
      let seed = parseInt(this.seeder);
      let m = array.length;
      let t;
      let i;

      // While there remain elements to shuffle…
      while (m) {

        // Pick a remaining element…
        i = Math.floor(this.random(seed) * m--);        // <-- MODIFIED LINE

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed;                                     // <-- ADDED LINE
      }

      return array;

    },
    random(seed) {
      seed = Math.sin(seed) * 100000;
      return seed - Math.floor(seed);

      var x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    },
    async init() {
      let defColumns;
      let defRows;
      if (this.sizeScreen === 'sm') {
        defColumns = this.smColumns;
        defRows = this.smRows;
      } else if (this.sizeScreen === 'md') {
        defColumns = this.mdColumns;
        defRows = this.mdRows;
      } else {
        defColumns = this.columns;
        defRows = this.rows;
      }
      let items = new Array(500).fill({});
      let finalItems = [];

      let content = await this.$api.internal.get('summary/flights-to')
          .then(response => {
            return response.data;
          });

      items = this.$helpers.shuffleSeeder(content, this.$moment().format('HHDDMMYYYY'));

      let index = 0;
      let column = 0;

      let boxSizes = new Array(100);
      boxSizes.fill(1, 0, 85);
      boxSizes.fill(2, 84, 95);
      boxSizes.fill(3, 94, 100);

      let boxSizesArray = this.$helpers.shuffleSeeder(boxSizes, this.$moment().format('HHDDMMYYYY'));

      for (let row = 0; row < defRows; row++) {
        column = 0;

        if (typeof finalItems[row] === 'undefined') {
          finalItems[row] = [];
        }

        while (column < defColumns) {
          let newColumnSize = boxSizesArray[boxSizesArray.length % (index + 1)];

          while (column + newColumnSize > defColumns) {
            newColumnSize--;
          }

          column += newColumnSize;

          // let content = await this.$api.internal.get('contents/flights-to-' + items[index].slug)
          //     .then(response => {
          //       return response.data;
          //     });

          finalItems[row].push({
            'size': newColumnSize,
            'item': items[index]
          });

          index++;
        }
      }

      this.items = finalItems;
    }
  },
  created() {
    this.init();
  }
};
