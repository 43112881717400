
import Title from "@/components/style/Title.vue";
import SecondTitle from "@/components/style/SecondTitle.vue";

let vueCarousel;
let components = {SecondTitle, Title};
if (process.client) {
  vueCarousel = require('vue-carousel');
  components.Carousel = vueCarousel.Carousel;
  components.Slide = vueCarousel.Slide;
  components.Pagination = vueCarousel.Pagination;
}

export default {
  components,
  data() {
    return {
      pages: null,
      scale: false
    };
  },
  created() {
    console.log(this.$router);
    if (process.client) {
      this.$nextTick(() => {
        this.scale = true;
      });
    }
  },
  async fetch() {
    const pages = await this.$api.contents.query({
      type: 'flights-to',
      multiple: true,
      limit: 30,
      orderBy: 'rand-seed',
      'metadata.type_location': 'same_city'
    }).then((res) => {
      return res.data.contents;
    });
    this.pages = pages;
  }
}
