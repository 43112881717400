
import Airport from '~/components/input-2/Airport.vue';
import Dropdown from '~/components/input/Dropdown.vue';
import Passengers from '~/components/input-2/Passengers.vue';
import DateTravel from '~/components/input/DateTravel';
import RadioGroup from "~/components/input-2/RadioGroup.vue";
import Radio from "~/components/input-2/Radio.vue";
import Validation from "~/components/input-2/Validation.vue";
import LabelHeader from "~/components/input-2/LabelHeader.vue";
import TravelDates from "@/components/input-2/TravelDates.vue";
import Button from "~/components/input-2/Button.vue";
import {extend} from "vee-validate";

// import Calendar   from '../components/Calendar.vue';
// import axios      from 'axios';

export default {
  components: {
    Button,
    TravelDates,
    LabelHeader,
    Validation,
    Radio,
    RadioGroup,
    DateTravel,
    Dropdown,
    Passengers,
    Airport
  },
  props: {
    form: {type: Object}
  },
  data() {
    return {
      loading: true,
      label: '',
      open_travelclass: false,
      open_calendar: false,
      open_passengers: false,
      errorNoDeparture: false,
      errorNoDepartureDate: false,
      hideAirportDeparture: {
        handler: this.hideAirport,
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behavior is behind a flag.
        detectIFrame: true,
        // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
        //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
        capture: true
      },
      hideAirportDestination: {
        handler: this.hideAirport,
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behavior is behind a flag.
        detectIFrame: true,
        // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
        //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
        capture: true
      }
    };
  },
  created() {
    extend('different', () => {
      if (this.$store.state.searchFlight.departure && this.$store.state.searchFlight.departure.city_code &&
          this.$store.state.searchFlight.destination && this.$store.state.searchFlight.destination.city_code &&
          this.$store.state.searchFlight.departure.city_code === this.$store.state.searchFlight.destination.city_code
      ) {
        console.log(this.$store.state.searchFlight.departure.city_code);
        console.log(this.$store.state.searchFlight.destination.city_code);
        return this.$t('search-flight.Departure and destination same')
      }
      return true;
    });
  },
  computed: {
    travelclass: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.travelclass;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setTravelclass', newValue);
      }
    },
    search_type: {
      get: function () {
        return this.$store.state.searchFlight.search_type;
      },
      set: function (newValue) {
        let original_search_type = this.$store.state.searchFlight.search_type;
        this.$store.commit('searchFlight/setSearchType', newValue);

        if (newValue === 'roundtrip' && original_search_type !== newValue) {
          if (this.$store.state.searchFlight.departure_date !== null) {
            this.$store.commit('searchFlight/setReturnDate', this.$moment(this.$store.state.searchFlight.departure_date).set("hour", 12).set("minute", 0).add(7, 'days'));
          }
        }
      }
    },
    departure: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.departure;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDeparture', newValue);
      }
    },
    destination: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.destination;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDestination', newValue);
      }
    },
    adults: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.adults;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setAdults', newValue);
      }
    },
    children: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.children;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setChildren', newValue);
      }
    },
    infants: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.infants;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setInfants', newValue);
      }
    }
  },
  methods: {
    hideAirport() {
      // this.$refs.departure.hide();
      // this.$refs.destination.hide();
    },
    swap() {
      console.log('dep', this.$store.state.searchFlight);
      console.log('des', this.$store.state.searchFlight.destination);
      let tmp = this.$store.state.searchFlight.departure;
      if (this.$store.state.searchFlight.destination && this.$store.state.searchFlight.destination.airport_code && this.$store.state.searchFlight.destination.airport_code !== 'X') {
        this.$store.commit('searchFlight/setDeparture', this.$store.state.searchFlight.destination);
      } else {
        this.$store.commit('searchFlight/setDeparture', '');
      }
      this.$store.commit('searchFlight/setDestination', tmp);
    },
    search() {
      this.$refs.observer.validate()
          .then((result) => {
            if (result) {
              let data = {
                departure: this.$store.state.searchFlight.departure.airport_code,
                destination: this.$store.state.searchFlight.destination.airport_code,
                adults: this.$store.state.searchFlight.adults,
                children: this.$store.state.searchFlight.children,
                infants: this.$store.state.searchFlight.infants,
                travelclass: this.$store.state.searchFlight.travelclass,
                search_type: this.$store.state.searchFlight.search_type,
                departure_date: this.$store.state.searchFlight.departure_date,
                return_date: this.$store.state.searchFlight.return_date
              };
              this.$api.flightSearch.code(data)
                  .then((res) => {

                    let path = this.localeRoute({
                      name: 'search-flight-code',
                      params: {code: encodeURIComponent(res.data.flight_search.search_code)}
                    }).fullPath;

                    console.log('path', path);

                    if (this.$route.name.split('___')[0] === 'search-flight-code' && this.$route.params && this.$route.params.code && this.$route.params.code === res.data.flight_search.search_code) {
                      console.log('same route');
                      window.location.href = path + '?' + Math.random();
                    } else {
                      this.$router.push({
                        path
                      });
                    }

                  });
            }
          });

    }
  },
  watch: {
    form: {
      immediate: true,
      handler(value) {
        if (typeof value !== 'undefined' && value !== null) {
          this.formLocal = value;
        }
      }
    }
  }
};
