
import Title from "@/components/style/Title.vue";

export default {
  components: {Title},
  props: {
    aircompanies: {},
    contents: {default: false},
    label: {default: false},
    gridMd: {default: '4'},
    grid: {default: '1'},
    gridLg: {default: '6'}
  }
}
